<template>
  <div id="user-edit">
    <vs-alert color="danger" :title="$t('administration.useredit.user_not_found.title')" :active.sync="isUserNotFound">
      <span>{{ $t('administration.useredit.user_not_found.message', { 'userId': $route.params.userId }) }}</span>
    </vs-alert>
    <vx-card v-if="user" class="pt-5 pb-5">
      <profile-form :user="user" @update:user="userUpdate" />
    </vx-card>
  </div>
</template>

<script>
import ProfileForm from '@/views/apps/user/user-form/ProfileForm'

import moduleUserManagementMixin from '@/store/user-management/moduleUserManagementMixin'

export default {
  components: {
    ProfileForm
  },
  metaInfo: {
    title: 'Edit your profile'
  },
  mixins: [moduleUserManagementMixin],
  data () {
    return {
      user: null,
      isUserNotFound: false
    }
  },
  methods: {
    fetchUser (userId) {
      this.$store.dispatch('userManagement/fetchUser', userId)
        .then(res => { this.user = res.data })
        .catch(err => {
          if (err.response.status === 404) {
            this.isUserNotFound = true
            return
          }
          console.error(err) // eslint-disable-line no-console
        })
    },
    userUpdate (user) {
      this.$store.dispatch('userManagement/updateUser', user)
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: this.$t('administration.userform.form.submit.success.notify.title'),
            text: this.$t('administration.userform.form.submit.success.notify.text', { username: user.username })
          })
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t('administration.userform.form.submit.error.notify.title'),
            text: this.$t('administration.userform.form.submit.error.notify.text', { error: error.response.data['hydra:description'] }),
            fixed: true
          })
        })
    }
  },
  created () {
    this.fetchUser(this.$route.params.userId)
  }
}
</script>

<style scoped>

</style>
